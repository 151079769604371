import React, { Component } from 'react';
import { List, Radio, Button, Calendar, Modal, Toast } from 'antd-mobile';
import { submit, get_student_list } from '../server/https'
import appointmentImg from "../static/img/appointment.jpg";
import enUS from 'antd-mobile/lib/calendar/locale/en_US';
import zhCN from 'antd-mobile/lib/calendar/locale/zh_CN';
import moment from "moment";

const RadioItem = Radio.RadioItem;

const now = new Date();

class appointment extends Component {
  originbodyScrollY = document.getElementsByTagName('body')[0].style.overflowY;
  constructor(props) {
    super(props);
    this.state = {
      value2: -1,
      config: {},
      startTime: '',
      endTime: '',
      child_list: [],
      index: -1,
      modal2: false,
      scheme_list: [],
      data2: []
    };
  }

  componentDidMount() {
    get_student_list().then(res => {
      if (res.code == 0) {
        let scheme_list = JSON.parse(localStorage.getItem('scheme_list'))
        let data2 = []
        scheme_list.map((item, index) => {
          let t = {
            value: item.id,
            label: item.name,
            extra: <div>
              <div style={{ marginBottom: '1rem' }}>方案介绍：{item.introduce}</div>
              <div style={{ marginBottom: '1rem' }}>服务社区：{item.service_house_str}</div>
              <div style={{ marginBottom: '1rem' }}>服务学校：{item.service_school_str}</div>
              <div style={{ marginBottom: '1rem' }}>服务时间：{item.service_time_str}</div>
              <div style={{ marginBottom: '1rem' }}>方案价格：{item.price}元</div>
            </div>
          }
          data2.push(t)
        })
        this.setState({
          child_list: res.data.list,
          scheme_list,
          data2
        })
      }
    })
  }

  onChange2 = (value) => {
    let i = this.state.value2
    if (value == i) {
      this.setState({
        value2: -1,
      })
    } else {
      this.setState({
        value2: value,
      })
    }
  };

  submit = () => {
    let data = {
      user_id: localStorage.getItem('user_id'),
      name: localStorage.getItem('nickname'),
      student_id: this.state.child_list[this.state.index].id,
      scheme_id: this.state.value2,
      mobile: '13312341234',
      start_date: this.state.startTime,
      end_date: this.state.endTime,
      remark: ''
    }
    submit(data).then(res => {
      if (res.code == 0) {
        Toast.info('预约成功！')
        this.setState({
          modal2: false
        })
      }
    })
  }

  renderBtn(zh, en, config = {}) {

    config.locale = this.state.en ? enUS : zhCN;

    return (
      <List.Item arrow="horizontal"
        onClick={() => {
          document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
          this.setState({
            show: true,
            config,
          });
        }}
        style={{ borderBottom: '1px solid #ddd' }}
      >
        {this.state.en ? en : zh}
      </List.Item>
    );
  }

  onSelectHasDisableDate = (dates) => {
    console.warn('onSelectHasDisableDate', dates);
  }

  onConfirm = (startTime, endTime) => {
    document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
    startTime = moment(startTime).format('YYYY-MM-DD')
    endTime = moment(endTime).format('YYYY-MM-DD')
    this.setState({
      show: false,
      startTime,
      endTime,
    });
  }

  onCancel = () => {
    document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
    this.setState({
      show: false,
      startTime: undefined,
      endTime: undefined,
    });
  }

  showModal = key => (e) => {
    e.preventDefault(); // 修复 Android 上点击穿透
    if (this.state.startTime && this.state.endTime && this.state.value2 != -1) {
      this.setState({
        [key]: true,
      });
    } else {
      Toast.info('请选择套餐和时间！', 1.5);
    }
  }

  onClose = key => () => {
    this.setState({
      [key]: false,
    });
  }

  onChange = (value) => {
    this.setState({
      index: value
    })
  };

  render() {
    const { value2 } = this.state;

    const data2 = this.state.data2
    return (
      <div>
        <img src={appointmentImg} style={{ width: '100%' }} alt="" />
        <List style={{ marginTop: '2rem' }}>
          {this.renderBtn('选择日期范围', 'Select Date Range')}
          <List.Item
            style={{ borderBottom: '1px solid #ddd', display: this.state.startTime && this.state.endTime ? '' : 'none' }}
          >
            {this.state.startTime}至{this.state.endTime}
          </List.Item>
          {data2.map((i, index) => (
            <div key={index} style={{ borderBottom: '1px solid #ddd' }}>
              <RadioItem key={i.value} checked={value2 === i.value} onChange={() => this.onChange2(i.value)}>
                {i.label}
              </RadioItem>
              <div style={value2 === i.value ? { borderTop: '1px solid #ddd', textAlign: 'left', padding: '12px 0 12px 15px', transition: 'height 2s', fontSize: '17px' } : { textAlign: 'left', fontSize: '17px', transition: 'height 2s', height: '0', overflow: 'hidden' }}>{i.extra}</div>
            </div>
          ))}
        </List>
        <Calendar
          {...this.state.config}
          visible={this.state.show}
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}
          onSelectHasDisableDate={this.onSelectHasDisableDate}
          defaultDate={now}
          minDate={new Date(+now + 86400000)}
          maxDate={new Date(+now + 31536000000)}
        />
        <Modal
          popup
          visible={this.state.modal2}
          onClose={this.onClose('modal2')}
          animationType="slide-up"
        >
          <List renderHeader={() => <div>选择孩子</div>} className="popup-list">
            {this.state.child_list.map((i, index) => (
              <RadioItem key={i.student} checked={this.state.index === index} onChange={() => this.onChange(index)}>
                {i.student}
              </RadioItem>
            ))}
            <List.Item>
              <Button type="primary" onClick={this.submit}>提交</Button>
            </List.Item>
          </List>
        </Modal>
        <Button type="primary" style={{ width: '80%', margin: '0 auto', marginTop: '40px', marginBottom: '80px' }} onClick={this.showModal('modal2')}>立即预约</Button>
      </div>
    );
  }
}
export default appointment