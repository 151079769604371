import React, { Component } from 'react';
import { } from 'antd-mobile';
import { get_order_list } from "../../server/https";

class my_order extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        };
    }

    componentDidMount() {
        get_order_list().then(res => {
            if (res.code == 0) {
                console.log(res.data)
                this.setState({
                    list: res.data.list
                })
            }
        })
    }

    render() {
        const { list } = this.state
        return (
            <div>
                {
                    list.map((item, index) => (
                        <div key={index} style={{ margin: '1rem 1rem', backgroundColor: '#fff', border: '1px #fff solid', borderRadius: '15px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', padding: '0.8rem' }}>
                                <div>
                                    <div>订单编号：{item.order_no}</div>
                                    <div>孩子姓名：{item.student}</div>
                                    <div>订单方案：{item.scheme}</div>
                                    <div>有效时间：{item.start_date}至{item.end_date}</div>
                                    <div>订单状态：{item.state == 1 ? '提交' : item.state == 2 ? '已确认' : item.state == '3' ? '已完成' : item.state == -1 ? '用户已取消' : '平台已取消'}</div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}
export default my_order