import React, { Component } from 'react';
import { Button, Modal, List, Picker, ImagePicker, Toast, TextareaItem } from 'antd-mobile';
import { createForm } from 'rc-form';
import axios from 'axios'
import { get_order_list, set_student_state, close_order } from "../../server/https";
import moment from 'moment'

const req = (url, params) => {
  return axios({
    method: "POST",
    url: url,
    headers: { 'Content-Type': 'multipart/form-data;charset=utf-8' },
    data: params
  });
};

const district = [
  {
    label: '接到',
    value: '1'
  },
  {
    label: '到学堂',
    value: '2'
  },
  {
    label: '离开学堂',
    value: '3'
  }
]


class my_order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      modal: false,
      files: [],
      params: {
        student_id: '',
        state: '',
        image: '',
        date: ''
      },
      stateval: '',
      closeModal: false,
      reason: ''
    };
  }

  componentDidMount() {
    this.get_order_list()
  }

  get_order_list = e =>{
    get_order_list().then(res => {
      if (res.code == 0) {
        console.log(res.data)
        this.setState({
          list: res.data.list
        })
      }
    })
  }

  orderChange = index => {
    this.setState({
      index,
      modal: true
    })
  }

  onClose = () => {
    this.setState({
      modal: false,
      closeModal: false
    });
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename + mime.split('/')[1], { type: mime });
  }

  upload = (files, type, index) => {
    if (type == 'add') {
      var fileName = (new Date()).getTime() + "."; //随机文件名
      var imgfile = this.dataURLtoFile(files[0].url, fileName); //转换成file
      var formData = new FormData();
      formData.append('upload_control', imgfile);
      formData.append('type', 1);
      req('https://sidianban.yanuojiaoyu.com/api/upload/upload_file', formData).then(res => {
        if (res.data.code == 0) {
          let s = this.state.params
          s['image'] = res.data.data.full_path
          this.setState({
            files,
            params: s
          });
        }
      })
    } else {
      let s = this.state.params
      s['image'] = ''
      this.setState({
        files,
        params: s
      });
    }
  }

  submit = () => {
    let data = this.state.params
    data.date = moment().format('YYYY-MM-DD')
    data.student_id = this.state.list[this.state.index].student_id
    data.order_id = this.state.list[this.state.index].id
    data.state = this.state.stateval
    set_student_state(data).then(res => {
      if (res.code == 0) {
        Toast.info('修改成功！')
        this.get_order_list()
        this.setState({
          modal: false,
          files: [],
          stateval: ''
        })
      }
    })
  }

  toDetail = id => {
    this.props.history.push('/my/order_management/' + id)
  }

  closeOrder = index => {
    this.setState({
      closeIndex: index,
      closeModal: true
    })
  }

  reasonChange = e => {
    this.setState({
      reason: e
    })
  }

  closeSubmit = e => {
    let data = {
      order_id: this.state.list[this.state.closeIndex].id,
      reason: this.state.reason
    }
    close_order(data).then(res => {
      if (res.code == 0) {
        Toast.info(res.message)
        this.get_order_list()
        this.setState({
          reason: '',
          closeModal: false
        })
      } else {
        Toast.fail(res.message)
      }
    })
  }

  render() {
    const { list } = this.state
    const { getFieldProps } = this.props.form;
    return (
      <div>
        {
          list.map((item, index) => (
            <div key={index} style={{ margin: '1rem 1rem', backgroundColor: '#fff', border: '1px #fff solid', borderRadius: '15px' }}>
              <div style={{ alignItems: 'center', padding: '0.8rem' }}>
                <div>
                  <div>订单编号：{item.order_no}</div>
                  <div>孩子姓名：{item.student}</div>
                  <div>订单方案：{item.scheme}</div>
                  <div>有效时间：{item.start_date}至{item.end_date}</div>
                  <div>订单状态：{item.state == 1 ? '提交' : item.state == 2 ? '已确认' : item.state == '3' ? '已完成' : item.state == -1 ? '用户已取消' : '平台已取消'}</div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      <Button type="primary" size="small" style={{ width: '80%', margin: '10px auto 0' }} onClick={e => this.orderChange(index)}>设置考勤</Button>
                    </div>
                    <div style={{ width: '50%' }}>
                      <Button size="small" style={{ width: '80%', margin: '10px auto 0' }} onClick={e => this.closeOrder(index)}>关闭订单</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
        <Modal
          popup
          visible={this.state.modal}
          onClose={this.onClose}
          animationType="slide-up"
        >
          <List renderHeader={() => <div>修改订单</div>} className="popup-list">
            <div style={{ width: '80px', height: '90px', overflow: 'hidden', margin: '0 auto', marginTop: '10px' }}>
              <ImagePicker
                files={this.state.files}
                onChange={this.upload}
                onImageClick={(index, fs) => console.log(index, fs)}
                selectable={this.state.files.length < 1}
                multiple={false}
                style={{ width: '340px', height: '80px', marginTop: '10px' }}
              />
            </div>
            <Picker data={district} cols={1} {...getFieldProps('district3')} className="forss" value={this.state.stateval} onChange={v => this.setState({ stateval: v })}>
              <List.Item arrow="horizontal">订单状态</List.Item>
            </Picker>
            <List.Item>
              <Button type="primary" onClick={this.submit}>提交</Button>
            </List.Item>
          </List>
        </Modal>
        <Modal
          popup
          visible={this.state.closeModal}
          onClose={this.onClose}
          animationType="slide-up"
        >
          <List renderHeader={() => <div>关闭订单</div>} className="popup-list">
            <TextareaItem
              {...getFieldProps('count', {
                initialValue: '',
              })}
              placeholder="请输入原因！"
              rows={5}
              value={this.state.reason}
              onChange={e => this.reasonChange(e)}
              count={100}
            />
            <List.Item>
              <Button type="primary" onClick={this.closeSubmit}>提交</Button>
            </List.Item>
          </List>
        </Modal>
      </div>
    );
  }
}
const TestWrapper = createForm()(my_order);
export default TestWrapper