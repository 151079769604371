import React, { Component } from 'react';
import { Button, Toast } from 'antd-mobile';
import { add_message } from "../../server/https";

class feedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: '',
            nickname: '',
            headimgurl: '',
            modal2: false
        };
    }

    componentDidMount() {
        let mobile = localStorage.getItem('mobile'),
            nickname = localStorage.getItem('nickname'),
            headimgurl = localStorage.getItem('headimgurl')
        this.setState({
            mobile,
            nickname,
            headimgurl
        })
    }

    showModal = key => (e) => {
        e.preventDefault(); // 修复 Android 上点击穿透
        this.setState({
            [key]: true,
        });
    }

    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }

    submit = e => {
        let data = {
            message: this.state.message
        }
        add_message(data).then(res => {
            if (res.code == 0) {
                Toast.info('提交成功，感谢您的反馈！我们会尽快给予答复！')
            }
        })
    }

    messageChange = e => {
        let val = document.getElementById('area')
        this.setState({
            message: val
        })
    }

    render() {
        return (
            <div>
                <div style={{ padding: '14px', backgroundColor: '#fff', marginBottom: '8px' }}>
                    <textarea id='area' style={{ width: '100%', height: '200px', resize: 'none', boxSizing: 'border-box', border: '1px solid #e0e5ee', outline: 'none', fontSize: '16px' }} type="text" placeholder="请输入您对学校或老师的投诉与建议（500字以内）" maxlength="500" onChange={e => this.messageChange(e)}></textarea>
                    <Button type="primary" style={{ width: '80%', margin: '0 auto', marginTop: '40px', marginBottom: '80px' }} onClick={this.submit}>添加</Button>
                </div>
            </div>
        );
    }
}
export default feedback