import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import TabBar from '../common/tab_bar'
import Home from '../pages/home'
import Appointment from '../pages/appointment'
import My from '../pages/my'
import Personal_information from "../pages/my/personal_information";
import My_child from "../pages/my/my_child";
import My_order from "../pages/my/my_order";
import Feedback from "../pages/my/feedback";
import Order_management from "../pages/my/order_management";
import Order_detail from "../pages/my/order_detail";

class router extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <Router basename="/">
                <div className="App">

                    <main>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/appointment" component={Appointment} />
                            <Route exact path="/my" component={My} />
                            <Route exact path="/my/personalinformation" component={Personal_information} />
                            <Route exact path="/my/mychild" component={My_child} />
                            <Route exact path="/my/myorder" component={My_order} />
                            <Route exact path="/my/feedback" component={Feedback} />
                            <Route exact path="/my/order_management" component={Order_management} />
                            <Route exact path="/my/order_management/:id" component={Order_detail} />
                        </Switch>
                    </main>

                    <footer style={{ position: 'fixed', bottom: '0', width: '100%' }}>
                        <TabBar />
                    </footer>

                </div>
            </Router>
        );
    }
}

export default router;