import React, { Component } from 'react';
import { } from 'antd-mobile';
import { Link } from 'react-router-dom'
import '../css/my.css'

class my extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: '',
            nickname: '',
            headimgurl: '',
            modal2: false,
            is_operator: false
        };
    }

    componentDidMount() {
        let mobile = localStorage.getItem('mobile'),
            nickname = localStorage.getItem('nickname'),
            headimgurl = localStorage.getItem('headimgurl'),
            is_operator = localStorage.getItem('is_operator')
        this.setState({
            mobile,
            nickname,
            headimgurl,
            is_operator
        })
    }

    showModal = key => (e) => {
        e.preventDefault(); // 修复 Android 上点击穿透
        this.setState({
            [key]: true,
        });
    }

    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }

    render() {
        const { mobile, nickname, headimgurl } = this.state
        return (
            <div>
                <div className="my-top">
                    <div className="header-img">
                        <img src={headimgurl} alt="" />
                    </div>
                    <div>
                        <div style={{ marginBottom: '1rem' }}>昵称：{nickname}</div>
                        <div>手机号：{mobile == 'null' ? '无' : mobile}</div>
                    </div>
                </div>
                <div>
                    <Link to='/my/personalinformation'>
                        <div className='menu-list' style={{ borderTop: '#ccc solid 0.1rem' }}>我的信息</div>
                    </Link>
                    <Link to='/my/mychild'>
                        <div className='menu-list'>我的孩子</div>
                    </Link>
                    <Link to='/my/myorder'>
                        <div className='menu-list'>我的订单</div>
                    </Link>
                    <Link to='/my/feedback'>
                        <div className='menu-list'>建议反馈</div>
                    </Link>
                    {
                        this.state.is_operator == 1 ?
                            <Link to='/my/order_management'>
                                <div className='menu-list'>订单管理</div>
                            </Link> : ''
                    }
                </div>
            </div>
        );
    }
}
export default my