import React, { Component } from 'react';
import { Modal, List, Button, InputItem, ImagePicker, Toast } from 'antd-mobile';
import { get_student_list, add_student, upload_file } from "../../server/https";
import axios from 'axios'
import '../../css/my_child.css'

const req = (url, params) => {
  return axios({
    method: "POST",
    url: url,
    headers: { 'Content-Type': 'multipart/form-data;charset=utf-8' },
    data: params
  });
};

class my_child extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal2: false,
      files: [],
      list: [],
      params: {
        student: '',
        sex: '',
        school: '',
        grade: '',
        class: '',
        mobile: '',
        picture: ''
      }
    };
  }

  componentDidMount() {
    this.get_student_list()
  }

  get_student_list = () => {
    get_student_list().then(res => {
      console.log(res.data.list)
      if (res.code == 0) {
        this.setState({
          list: res.data.list
        })
      }
    })
  }

  showModal = key => (e) => {
    e.preventDefault(); // 修复 Android 上点击穿透
    this.setState({
      [key]: true,
    });
  }

  onClose = key => () => {
    this.setState({
      [key]: false,
    });
  }

  onChange = (e, id) => {
    let s = this.state.params
    s[id] = e
    this.setState({
      params: s,
    });
  }

  submit = () => {
    let data = this.state.params
    data.user_id = localStorage.getItem('user_id')
    add_student(data).then(res => {
      if (res.code == 0) {
        Toast.info('添加成功！')
        this.get_student_list()
        this.setState({
          modal2: false,
        });
      }
    })
  }


  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename + mime.split('/')[1], { type: mime });
  }

  upload = (files, type, index) => {
    if (type == 'add') {
      var fileName = (new Date()).getTime() + "."; //随机文件名
      var imgfile = this.dataURLtoFile(files[0].url, fileName); //转换成file
      var formData = new FormData();
      formData.append('upload_control', imgfile);
      console.log(imgfile)
      formData.append('type', 1);
      req('https://sidianban.yanuojiaoyu.com/api/upload/upload_file', formData).then(res => {
        if (res.data.code == 0) {
          let s = this.state.params
          s['picture'] = res.data.data.full_path
          this.setState({
            files,
            params: s
          });
        }
      })
    } else {
      let s = this.state.params
      s['picture'] = ''
      this.setState({
        files,
        params: s
      });
    }
  }

  render() {
    let list = [
      {
        id: 'student',
        name: '孩子姓名',
      }, {
        id: 'sex',
        name: '性别',
      }, {
        id: 'school',
        name: '就读学校',
      }, {
        id: 'grade',
        name: '就读年级',
      }, {
        id: 'class',
        name: '就读班级',
      }, {
        id: 'mobile',
        name: '联系方式'
      }
    ]
    return (
      <div>
        <div>
          {
            this.state.list.map((item, index) => (
              <div key={index} style={{ display: 'flex', margin: '1rem 1rem', backgroundColor: '#fff' }}>
                <div style={{ width: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={item.picture} />
                </div>
                <div style={{ width: '60%', display: 'flex', alignItems: 'center', padding: '0.5rem 0.5rem' }}>
                  <div>
                    <div style={{ marginBottom: '0.4rem' }}>孩子姓名：{item.student}</div>
                    <div style={{ marginBottom: '0.4rem' }}>就读学校：{item.school}</div>
                    <div style={{ marginBottom: '0.4rem' }}>就读年级：{item.grade}</div>
                    <div style={{ marginBottom: '0.4rem' }}>就读班级：{item.class}</div>
                    <div style={{ marginBottom: '0.4rem' }}>联系方式：{item.mobile}</div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>

        <Modal
          popup
          visible={this.state.modal2}
          onClose={this.onClose('modal2')}
          animationType="slide-up"
        >
          <List renderHeader={() => <div>添加孩子</div>} className="popup-list">
            <div style={{ width: '80px', height: '90px', overflow: 'hidden', margin: '0 auto', marginTop: '10px' }}>
              <ImagePicker
                files={this.state.files}
                onChange={this.upload}
                onImageClick={(index, fs) => console.log(index, fs)}
                selectable={this.state.files.length < 1}
                multiple={false}
                style={{ width: '340px', height: '80px', marginTop: '10px' }}
              />
            </div>
            {list.map((item, index) => (
              <InputItem
                key={index}
                placeholder="请输入"
                clear
                onChange={(e) => this.onChange(e, item.id)}
              >{item.name}</InputItem>
            ))}
            <List.Item>
              <Button type="primary" onClick={this.submit}>提交</Button>
            </List.Item>
          </List>
        </Modal>
        <Button type="primary" style={{ width: '80%', margin: '0 auto', marginTop: '40px', marginBottom: '80px' }} onClick={this.showModal('modal2')}>添加</Button>
      </div>
    );
  }
}
export default my_child