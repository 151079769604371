import './App.css';
import RouterIndex from './router/router'

function App() {
  return (
    <RouterIndex />
  );
}

export default App;
