import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { TabBar } from 'antd-mobile';
import {
  HomeOutlined,
  UserOutlined,
  BellOutlined
} from '@ant-design/icons';

class TabBarExample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: '',
      hidden: false,
      fullScreen: true,
    };
  }

  componentDidMount() {
    let selectedTab = this.props.history.location.pathname
    selectedTab = selectedTab.split('/')
    let newSelect = '/' + selectedTab[1]
    console.log(selectedTab)
    this.setState({
      selectedTab: newSelect
    })
  }

  render() {
    return (
      <div>
        <TabBar unselectedTintColor="#949494" tintColor="#33A3F4" barTintColor="white">
          <TabBar.Item
            title="首页"
            key="home"
            icon={
              <HomeOutlined style={{ width: '22px', height: '22px', fontSize: '22px' }} />
            }
            selectedIcon={
              <HomeOutlined style={{ width: '22px', height: '22px', fontSize: '22px' }} />
            }
            selected={this.state.selectedTab === '/'}
            onPress={() => {
              this.props.history.push('/');
              this.setState({ selectedTab: '/', });
            }}
            data-seed="logId"
          ></TabBar.Item>
          <TabBar.Item
            title="预约"
            key="appointment"
            icon={
              <BellOutlined style={{ width: '22px', height: '22px', fontSize: '22px' }} />
            }
            selectedIcon={
              <BellOutlined style={{ width: '22px', height: '22px', fontSize: '22px' }} />
            }
            selected={this.state.selectedTab === '/appointment'}
            onPress={() => {
              this.props.history.push('/appointment');
              this.setState({ selectedTab: '/appointment', });
            }}
            data-seed="logId1"
          ></TabBar.Item>
          <TabBar.Item
            title="我"
            key="my"
            icon={
              <UserOutlined style={{ width: '22px', height: '22px', fontSize: '22px' }} />
            }
            selectedIcon={
              <UserOutlined style={{ width: '22px', height: '22px', fontSize: '22px' }} />
            }
            selected={this.state.selectedTab === '/my'}
            onPress={() => {
              this.props.history.push('/my');
              this.setState({ selectedTab: '/my', });
            }}
          ></TabBar.Item>
        </TabBar>
      </div >
    );
  }
}
export default withRouter(TabBarExample)