import React, { Component } from 'react';
import { init } from '../server/https';
import homeImg from "../static/img/home.jpg";

class home extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        let search = this.props.history.location.search,
            token = ''
        if (search) {
            token = search.replace('?', '')
            token = token.split('&')
            token = token[0].split('=')[1]
            localStorage.setItem('token', token)
            let data = {
                token: token
            }
            init(data).then(res => {
                if (res.code == 0) {
                    let user_model = res.data.user_model
                    localStorage.setItem('model', JSON.stringify(res.data.model))
                    localStorage.setItem('scheme_list', JSON.stringify(res.data.scheme_list))
                    localStorage.setItem('school_list', JSON.stringify(res.data.school_list))
                    localStorage.setItem('user_id', user_model.id)
                    localStorage.setItem('openid', user_model.openid)
                    localStorage.setItem('xuetang_id', user_model.xuetang_id)
                    localStorage.setItem('nickname', user_model.nickname)
                    localStorage.setItem('headimgurl', user_model.headimgurl)
                    localStorage.setItem('mobile', user_model.mobile)
                    localStorage.setItem('is_operator', user_model.is_operator)
                }
            })
        }
    }

    render() {
        return (
            <div style={{ minHeight: '100vh',backgroundColor:'#96cb63' }}>
                <img src={homeImg} style={{ width: '100%' }} alt="" />
            </div>
        );
    }
}
export default home