import React, { Component } from 'react';
import { Modal, List, Button, InputItem, Toast } from 'antd-mobile';
import { get_info, set_info } from '../../server/https'

class personal_information extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal2: false,
            setParams: {},
            data: {}
        };
    }

    componentDidMount() {
        get_info().then(res => {
            if (res.code == 0) {
                this.setState({
                    data: res.data.model
                })
            }
        })
    }

    showModal = key => (e) => {
        e.preventDefault(); // 修复 Android 上点击穿透
        this.setState({
            [key]: true,
        });
    }

    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }

    onChange = (e, id) => {
        let t = this.state.setParams
        t[id] = e
        this.setState({
            setParams: t
        })
    }

    set_info = () => {
        let data = this.state.setParams
        set_info(data).then(res => {
            if (res.code == 0) {
                Toast.info('修改成功！')
                this.setState({
                    modal2: false
                })
            }
        })
    }

    render() {
        return (
            <div>
                <div style={{ backgroundColor: '#fff', fontSize: '16px' }}>
                    <div style={{ display: 'flex', padding: '1rem 0', borderBottom: '#ccc solid 1px' }}>
                        <div style={{ width: '35%', paddingLeft: '2rem' }}>姓名：</div>
                        <div style={{ width: '65%' }}>{this.state.data.truename}</div>
                    </div>
                    <div style={{ display: 'flex', padding: '1rem 0', borderBottom: '#ccc solid 1px' }}>
                        <div style={{ width: '35%', paddingLeft: '2rem' }}>手机号：</div>
                        <div style={{ width: '65%' }}>{this.state.data.mobile}</div>
                    </div>
                </div>
                <Modal
                    popup
                    visible={this.state.modal2}
                    onClose={this.onClose('modal2')}
                    animationType="slide-up"
                >
                    <List renderHeader={() => <div>修改信息</div>} className="popup-list">
                        <InputItem
                            placeholder="请输入"
                            clear
                            onChange={(e) => this.onChange(e, 'truename')}
                        >姓名：</InputItem>
                        <InputItem
                            placeholder="请输入"
                            clear
                            onChange={(e) => this.onChange(e, 'mobile')}
                        >手机号：</InputItem>
                        <List.Item>
                            <Button type="primary" onClick={this.set_info}>提交</Button>
                        </List.Item>
                    </List>
                </Modal>
                <Button type="primary" style={{ width: '80%', margin: '0 auto', marginTop: '40px', marginBottom: '80px' }} onClick={this.showModal('modal2')}>修改</Button>
            </div >
        );
    }
}

export default personal_information