import { get, post } from "./axios";

//通用模块
//图片上传
export const upload_file = (params) => {
    return post('/api/upload/upload_file', params)
}

//四点半学堂
//初始化四点半学堂信息接口
export const init = (params) => {
    return post('/api/index/init', params)
}

//添加孩子
export const add_student = (params) => {
    return post('/api/index/add_student', params)
}

//提交订单
export const submit = (params) => {
    return post('/api/index/submit', params)
}

//提交投诉建议
export const add_message = (params) => {
    return post('/api/index/add_message', params)
}

//获取订单列表
export const get_order_list = (params) => {
    return post('/api/user/get_order_list', params)
}

//获取孩子列表
export const get_student_list = (params) => {
    return post('/api/user/get_student_list', params)
}

//获取某个学生考勤记录
export const get_student_daily = (params) => {
    return post('/api/user/get_student_daily', params)
}

//获取自己授权微信信息
export const get_info = (params) => {
    return post('/api/user/get_info', params)
}

//老师操作接口
//获取学生列表
export const get_student_list_teach = (params) => {
    return post('/api/teacher/get_student_list', params)
}

//获取所有订单
export const get_order_list_teach = (params) => {
    return post('/api/teacher/get_order_list', params)
}

//老师确认订单
export const confirm_order = (params) => {
    return post('/api/teacher/confirm_order', params)
}

//关闭订单
export const close_order = (params) => {
    return post('/api/teacher/close_order', params)
}

//设置考勤状态
export const set_student_state = (params) => {
    return post('/api/teacher/set_student_state', params)
}

//获取某个学生考勤记录
export const get_student_daily_teach = (params) => {
    return post('/api/teacher/get_student_daily', params)
}

//获取某个学生考勤记录
export const set_info = (params) => {
    return post('/api/user/set_info', params)
}

//获取订单详情
export const get_order_detail = (params) => {
    return post('/api/user/get_order_detail', params)
}